import logo from './logo.svg';
import './App.css';
import {Container, Row, Col, Form, Button, Modal} from 'react-bootstrap'
import DatePicker from 'react-datepicker'
import {useState, useEffect, useRef} from 'react'
import toast from 'react-hot-toast';
import Header from './components/Header'
import {tickers} from './App'
import api from './utils/Api'
import _ from 'lodash'
import moment from 'moment'
import {useSearchParams} from 'react-router-dom'
import { CSVLink, CSVDownload } from "react-csv";

const DatepickerInput = ({ ...props }) => {
  return <Form.Control {...props} readOnly={!props.enableTyping} />
};


export const escapeCsv = (item) => {
  for (let i in item) {
    if (typeof item[i] != 'string') continue

    item[i] = item[i].replaceAll(/"/g, '""').replaceAll(/,/g, '\,').replaceAll(/'/g, '\'');
  }

  return item
}

function Home() {
  const [form, setForm] = useState({})
  const [csvData, setCsvData] = useState([])
  const [showLink, setShowLink] = useState(false)
  const csvLink = useRef(null)
  const user = JSON.parse(localStorage.getItem('@PLI:user'))
  const [searchParams] = useSearchParams()
  const id  = searchParams.get('id')

  const canExport = user.canDelete

  useEffect(()=>{
    if (!id) return

    api.request('report-input/latest', (r)=>{
      if (!r) return

      setForm(r)
    }, {id})

  }, [id])

  useEffect(()=>{
    if (!form.date || !form.ticker) return

    api.request('report-input/latest', (r)=>{
      if (!r) {
        setForm({
          date: form.date,
          ticker: form.ticker,
          headline: '',
          rating: '',
          boldLede: '',
          bullet1: '',
          bullet2: '',
          bullet3: '',
          bullet4: '',
          bullet5: '',
          para1: '',
          para2: '',
          para3: '',
          para4: '',
          para5: '',
          sourceQuotes: ''
        })

        return
      }
      setForm({...form, ...r})
    }, {date: form.date, ticker: form.ticker})

  }, [form.date, form.ticker])

  useEffect(()=>{
    api.request('report-input', (r)=>{
      setCsvData(r.map((item, index)=>({
        ...escapeCsv(item),
        no: index+1,
        date: moment(item.date).format('MM/DD/YYYY'),
      })))
    })
  },[])

  return (
    <div className="App">
      <Header />
      <div className="form-container">
      { canExport &&
        <div className="d-flex justify-content-end">
          <Button onClick={()=>{
            setTimeout(()=>csvLink.current.link.click(), 200)
          }}>Export CSV</Button>
        </div>
      }
      <Form onSubmit={(e)=>{
        e.preventDefault()
        const err = []

        if (!form.date) {
          err.push('Target Publication Date is required.')
        }

        if (!form.ticker) {
          err.push('Ticker is required.')
        }

        if (!form.rating) {
          err.push('Rating is required.')
        }
        if (err.length) {
          toast.error(err.join("\n"))
          return
        }
        const user = JSON.parse(localStorage.getItem('@PLI:user'))

        api.request('report-input', (r,s)=>{
          toast.success('Submit successfully !')
          setForm(r)

          if (!canExport) {
            setCsvData([r].map((item, index)=>({
              ...escapeCsv(item),
              no: index+1,
              date: moment(item.date).format('MM/DD/YYYY'),
            })))
            setTimeout(()=>csvLink.current.link.click(), 200)
         }

        }, api.form({...form, email: user.email}), 'PATCH')

      }}>
      <Container>
        <Row>
          <Col xs={12}>
            <Form.Label>Target Publication Date <span className="text-danger">*</span></Form.Label>
            <DatePicker selected={form.date} onChange={(date)=>{
              setForm({...form, date})
            }} customInput={<DatepickerInput />} />
          </Col>
          <Col xs={12} className="mt-3">
            <Form.Label>Ticker <span className="text-danger">*</span></Form.Label>
            <Form.Select onChange={(e)=>{
              setForm({...form, ticker: e.target.value})
            }} aria-label="Default select example">
            <option data-hook="native-option-PLACEHOLDER" data-index="0" value="Select an option">
            Select an option</option>
            {
              tickers.map((ticker, index)=>(
                <option selected={ticker === form.ticker} key={index} value={ticker}>{ticker}</option>
              ))
            }
            </Form.Select>
          </Col>

          <Col xs={12}  className="mt-3">
            <Form.Label>Headline <span className="text-danger">*</span></Form.Label>
            <Form.Control name="headline" onChange={(e)=>setForm({...form, headline: e.target.value})}
              value={form.headline} as="textarea" className="mh-200"
             placeholder="Enter text here" required />
          </Col>
          <Col xs={12}  className="mt-3">
            <Form.Label>Rating <span className="text-danger">*</span></Form.Label>
            <Form.Select onChange={(e)=>{
              setForm({...form, rating: e.target.value})
            }} aria-label="Default select example">
            <option>Select an option</option>
            <option selected={form.rating == 'buy'} value="buy">Buy</option>
            <option selected={form.rating == 'neutral'} value="neutral">Neutral</option>
            <option selected={form.rating == 'sell'} value="sell">Sell</option>
            </Form.Select>
          </Col>
          <Col xs={12}  className="mt-3">
            <Form.Label>Bold Lede <span className="text-danger">*</span></Form.Label>
            <Form.Control name="boldLede" onChange={(e)=>setForm({...form, boldLede: e.target.value})}
              value={form.boldLede}
               as="textarea"
               className="mh-200"
             placeholder="Enter text here" required />
          </Col>
          <Col xs={12}  className="mt-3">
            <Form.Label>Bullet 1 <span className="text-danger">*</span></Form.Label>
            <Form.Control name="bullet1" onChange={(e)=>setForm({...form, bullet1: e.target.value})}
              value={form.bullet1} className="mh-250" as="textarea"
             placeholder="Enter text here" required />
          </Col>
          <Col xs={12}  className="mt-3">
            <Form.Label>Bullet 2 <span className="text-danger">*</span></Form.Label>
            <Form.Control name="bullet2" onChange={(e)=>setForm({...form, bullet2: e.target.value})}
              value={form.bullet2} className="mh-250" as="textarea"
             placeholder="Enter text here" required />
          </Col>
          <Col xs={12}  className="mt-3">
            <Form.Label>Bullet 3 <span className="text-danger">*</span></Form.Label>
            <Form.Control name="bullet3" onChange={(e)=>setForm({...form, bullet3: e.target.value})}
              value={form.bullet3} className="mh-250" as="textarea"
             placeholder="Enter text here" required />
          </Col>
          <Col xs={12}  className="mt-3">
            <Form.Label>Bullet 4</Form.Label>
            <Form.Control name="bullet4" onChange={(e)=>setForm({...form, bullet4: e.target.value})}
              value={form.bullet4} className="mh-250" as="textarea"
             placeholder="Enter text here" />
          </Col>
          <Col xs={12}  className="mt-3">
            <Form.Label>Bullet 5</Form.Label>
            <Form.Control name="bullet5" onChange={(e)=>setForm({...form, bullet5: e.target.value})}
              value={form.bullet5} className="mh-250" as="textarea"
             placeholder="Enter text here" />
          </Col>
          <Col xs={12}  className="mt-3">
            <Form.Label>Paragraph 1 - Ad Spending <span className="text-danger">*</span></Form.Label>
            <Form.Control as="textarea" name="para1" onChange={(e)=>setForm({...form, para1: e.target.value})}
              value={form.para1} className="mh-400"
             placeholder="Enter text here" required />
          </Col>
          <Col xs={12}  className="mt-3">
            <Form.Label>Paragraph 2 - Ad Prices <span className="text-danger">*</span></Form.Label>
            <Form.Control as="textarea" name="para2" onChange={(e)=>setForm({...form, para2: e.target.value})}
              value={form.para2} className="mh-400"
             placeholder="Enter text here" required />
          </Col>
          <Col xs={12}  className="mt-3">
            <Form.Label>Paragraph 3 - Engagement Metrics</Form.Label>
            <Form.Control as="textarea" name="para3" onChange={(e)=>setForm({...form, para3: e.target.value})}
              value={form.para3} className="mh-400"
             placeholder="Enter text here"  />
          </Col>
          <Col xs={12}  className="mt-3">
            <Form.Label>Paragraph 4 - Placeholder</Form.Label>
            <Form.Control as="textarea" name="para4" onChange={(e)=>setForm({...form, para4: e.target.value})}
              value={form.para4} className="mh-400"
             placeholder="Enter text here"  />
          </Col>
          <Col xs={12}  className="mt-3">
            <Form.Label>Paragraph 5 - Placeholder</Form.Label>
            <Form.Control as="textarea" name="para5" onChange={(e)=>setForm({...form, para5: e.target.value})}
              value={form.para5} className="mh-400"
             placeholder="Enter text here"  />
          </Col>
          <Col xs={12}  className="mt-3">
            <Form.Label>Source Quotes <span className="text-danger">*</span></Form.Label>
            <Form.Control as="textarea" name="sourceQuotes" onChange={(e)=>setForm({...form, sourceQuotes: e.target.value})}
              value={form.sourceQuotes} className="mh-400"
             placeholder="Enter text here" required />
          </Col>
          <Col xs={12} className="d-flex justify-content-center my-3">
            <Button type="submit">Submit</Button>
            { canExport &&
            <Button type="button" variant="outline-primary" className="ms-3" onClick={(e)=>{
              e.preventDefault()
              if (!form.id) {
                alert('No data for this ticker/date yet, please submit the form before sharing the link.')
                return
              }

              setShowLink(true)
            }}>Share a link</Button>
            }
          </Col>
        </Row>
      </Container>
      </Form>
      </div>
      <CSVLink
        filename={`quarterly_report_${moment(canExport?undefined:form.date).format('MM-DD-YYYY')}.csv`}
        data={csvData}
        headers={[
          {label: 'No.', key: 'no'},
          {label: 'Ticker', key: 'ticker'},
          {label: 'Date', key: 'date'},
          {label: 'Headline', key: 'headline'},
          {label: 'Rating', key: 'rating'},
          {label: 'Bold Lede', key: 'boldLede'},
          {label: 'Bullet 1', key: 'bullet1'},
          {label: 'Bullet 2', key: 'bullet2'},
          {label: 'Bullet 3', key: 'bullet3'},
          {label: 'Bullet 4', key: 'bullet4'},
          {label: 'Bullet 5', key: 'bullet5'},
          {label: 'Paragraph 1 - Ad Spending', key: 'para1'},
          {label: 'Paragraph 2 - Ad Prices', key: 'para2'},
          {label: 'Paragraph 3 - Engagement Metrics', key: 'para3'},
          {label: 'Paragraph 4 - Placeholder', key: 'para4'},
          {label: 'Paragraph 5 - Placeholder', key: 'para5'},
          {label: 'Source Quotes', key: 'sourceQuotes'},
        ]}
        ref={csvLink}
      />
      <Modal show={showLink} onHide={()=>setShowLink(false)}>
        <Modal.Header closeButton>Share a link</Modal.Header>
        <Modal.Body>
          <div className="d-flex">
            <input style={{flex: 1}} disabled value={api.url.admin+`?id=${form.id}`} />
            <Button className="ms-2" onClick={()=>{
              navigator.clipboard.writeText(api.url.admin+`?id=${form.id}`);
            }}>Copy link</Button>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
}

export default Home;
