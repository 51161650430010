import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import Offcanvas from 'react-bootstrap/Offcanvas';
import api from '../utils/Api'
import { matchRoutes, useLocation } from "react-router-dom"

const expand = 'md'

function OffcanvasExample() {
  const location = useLocation()

  const userStore = localStorage.getItem('@PLI:user')
  const user = userStore?JSON.parse(userStore):null

  return (
    <>
        <Navbar key={expand} expand={expand} variant="dark">
          <Container>
            <Navbar.Brand href="/">TMT Insights</Navbar.Brand>
            <Navbar.Toggle aria-controls={`offcanvasNavbar-expand-${expand}`} />
            <Navbar.Offcanvas
              id={`offcanvasNavbar-expand-${expand}`}
              aria-labelledby={`offcanvasNavbarLabel-expand-${expand}`}
              placement="end"
            >
              <Offcanvas.Header closeButton>
                <Offcanvas.Title id={`offcanvasNavbarLabel-expand-${expand}`}>
                  Menu
                </Offcanvas.Title>
              </Offcanvas.Header>
              <Offcanvas.Body>
                <Nav className="justify-content-end flex-grow-1 pe-3">
                  {
                  !user.readOnly &&
                  <>
                    <NavDropdown
                      active={location.pathname == '/' || location.pathname == '/ad-hoc'}
                      title={"Report Inputs"}
                      id={`offcanvasNavbarDropdown-report-${expand}`}
                    >
                      <NavDropdown.Item active={location.pathname == '/'} href="/">Quarterly Report</NavDropdown.Item>
                      <NavDropdown.Item active={location.pathname == '/ad-hoc'}
                       href="/ad-hoc">Ad Hoc Report</NavDropdown.Item>
                    </NavDropdown>
                    {/*<Nav.Link active={location.pathname == '/'} href="/">Report Inputs</Nav.Link>*/}
                    <Nav.Link active={false} target="__blank" href="https://docs.google.com/spreadsheets/d/1txEMGmEpJ23XEtEXuXVgBC_sD1IOhTqXe-Z38gYUetY/edit?gid=0#gid=0">
                      Number Inputs
                    </Nav.Link>
                    <Nav.Link active={location.pathname == '/source-input'} href="/source-input">Source Inputs</Nav.Link>
                    <Nav.Link active={location.pathname == '/schedule'} href="/schedule">Scheduling Inputs</Nav.Link>
                  </>
                  }
                  <Nav.Link active={location.pathname == '/past-report'} href="/past-report">Past Reports</Nav.Link>
                  { !!user &&
                  <NavDropdown
                    title={"Profile"}
                    id={`offcanvasNavbarDropdown-expand-${expand}`}
                  >
                    <NavDropdown.Item disabled>Logged in as: {user.fullName}</NavDropdown.Item>
                    {
                      user.canDelete &&
                    <NavDropdown.Item href="/log-in-history">Log-in History</NavDropdown.Item>
                    }
                    <NavDropdown.Divider />
                    <NavDropdown.Item onClick={()=>{
                      api.request('logout', (r)=>{
                        localStorage.removeItem('@PLI:api_token');
                        localStorage.removeItem('@PLI:user');
                        window.location.href = '/login'
                      }, {}, 'POST')
                    }}>
                      Logout
                    </NavDropdown.Item>
                  </NavDropdown>
                  }
                </Nav>
              </Offcanvas.Body>
            </Navbar.Offcanvas>
          </Container>
        </Navbar>
    </>
  );
}

export default OffcanvasExample;
