import logo from './logo.svg';
import './App.css';
import {Container, Row, Col, Form, Button, Modal} from 'react-bootstrap'
import DatePicker from 'react-datepicker'
import {useState, useEffect, useRef} from 'react'
import toast from 'react-hot-toast';
import Header from './components/Header'
import {tickers} from './App'
import api from './utils/Api'
import _ from 'lodash'
import moment from 'moment'
import {useSearchParams} from 'react-router-dom'
import { CSVLink, CSVDownload } from "react-csv";

const DatepickerInput = ({ ...props }) => {
  return <Form.Control {...props} readOnly={!props.enableTyping} />
};


export const escapeCsv = (item) => {
  for (let i in item) {
    if (typeof item[i] != 'string') continue

    item[i] = item[i].replaceAll(/"/g, '""').replaceAll(/,/g, '\,').replaceAll(/'/g, '\'');
  }

  return item
}

function AdHoc() {
  const [form, setForm] = useState({})
  const [csvData, setCsvData] = useState([])
  const [showLink, setShowLink] = useState(false)
  const csvLink = useRef(null)
  const user = JSON.parse(localStorage.getItem('@PLI:user'))
  const [searchParams] = useSearchParams()
  const id  = searchParams.get('id')

  const canExport = user.canDelete

  useEffect(()=>{
    if (!id) return

    api.request('ad-hoc/' + id, (r)=>{
      if (!r) return

      setForm(r)
    }, {id})

  }, [id])

  useEffect(()=>{
    api.request('ad-hoc', (r)=>{
      setCsvData(r.map((item, index)=>({
        ...escapeCsv(item),
        no: index+1,
        date: moment(item.date).format('MM/DD/YYYY'),
      })))
    })
  },[])

  return (
    <div className="App">
      <Header />
      <div className="form-container">
      { canExport &&
        <div className="d-flex justify-content-end">
          <Button onClick={()=>{
            setTimeout(()=>csvLink.current.link.click(), 200)
          }}>Export CSV</Button>
        </div>
      }
      <Form onSubmit={(e)=>{
        e.preventDefault()
        const err = []

        if (!form.date) {
          err.push('Target Publication Date is required.')
        }

        if (!form.rating) {
          err.push('Rating is required.')
        }
        if (err.length) {
          toast.error(err.join("\n"))
          return
        }
        const user = JSON.parse(localStorage.getItem('@PLI:user'))

        api.request('ad-hoc', (r,s)=>{
          toast.success('Submit successfully !')
          setForm(r)
          
          if (!canExport) {
            setCsvData([r].map((item, index)=>({
              ...escapeCsv(item),
              no: index+1,
              date: moment(item.date).format('MM/DD/YYYY'),
            })))
            setTimeout(()=>csvLink.current.link.click(), 200)
         }
        }, api.form({...form, email: user.email}), 'POST')

      }}>
      <Container>
        <Row>
          <Col xs={12}>
            <Form.Label>Target Publication Date <span className="text-danger">*</span></Form.Label>
            <DatePicker selected={form.date} onChange={(date)=>{
              setForm({...form, date})
            }} customInput={<DatepickerInput />} />
          </Col>
          <Col xs={12}  className="mt-3">
            <Form.Label>Headline <span className="text-danger">*</span></Form.Label>
            <Form.Control name="headline" onChange={(e)=>setForm({...form, headline: e.target.value})}
              value={form.headline} as="textarea" className="mh-200"
             placeholder="Enter text here" required />
          </Col>
          <Col xs={12}  className="mt-3">
            <Form.Label>Rating <span className="text-danger">*</span></Form.Label>
            <Form.Select onChange={(e)=>{
              setForm({...form, rating: e.target.value})
            }} aria-label="Default select example">
            <option>Select an option</option>
            <option selected={form.rating == 'bullish'} value="bullish">Bullish</option>
            <option selected={form.rating == 'neutral'} value="neutral">Neutral</option>
            <option selected={form.rating == 'bearish'} value="bearish">Bearish</option>
            </Form.Select>
          </Col>
          <Col xs={12}  className="mt-3">
            <Form.Label>Bold Lede <span className="text-danger">*</span></Form.Label>
            <Form.Control name="boldLede" onChange={(e)=>setForm({...form, boldLede: e.target.value})}
              value={form.boldLede}
               as="textarea"
               className="mh-200"
             placeholder="Enter text here" required />
          </Col>
          <Col xs={12}  className="mt-3">
            <Form.Label>Content <span className="text-danger">*</span></Form.Label>
            <Form.Control name="bullet1" onChange={(e)=>setForm({...form, content: e.target.value})}
              value={form.content} className="mh-250" as="textarea"
             placeholder="Enter text here" required />
          </Col>
          <Col xs={12} className="d-flex justify-content-center my-3">
            <Button type="submit">Submit</Button>
            { canExport &&
            <Button type="button" variant="outline-primary" className="ms-3" onClick={(e)=>{
              e.preventDefault()
              if (!form.id) {
                alert('Please submit the form before sharing the link.')
                return
              }

              setShowLink(true)
            }}>Share a link</Button>
            }
          </Col>
        </Row>
      </Container>
      </Form>
      </div>
      <CSVLink
        filename={`ad_hoc_report_${moment(canExport?undefined:form.date).format('MM-DD-YYYY')}.csv`}
        data={csvData}
        headers={[
          {label: 'No.', key: 'no'},
          {label: 'Date', key: 'date'},
          {label: 'Headline', key: 'headline'},
          {label: 'Rating', key: 'rating'},
          {label: 'Bold Lede', key: 'boldLede'},
          {label: 'Content', key: 'content'},
        ]}
        ref={csvLink}
      />
      <Modal show={showLink} onHide={()=>setShowLink(false)}>
        <Modal.Header closeButton>Share a link</Modal.Header>
        <Modal.Body>
          <div className="d-flex">
            <input style={{flex: 1}} disabled value={api.url.admin+`/ad-hoc?id=${form.id}`} />
            <Button className="ms-2" onClick={()=>{
              navigator.clipboard.writeText(api.url.admin+`/ad-hoc?id=${form.id}`);
            }}>Copy link</Button>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
}

export default AdHoc;
