import { Document, Page } from 'react-pdf'
import {useState, useEffect} from 'react'
import api from './utils/Api'
import {useSearchParams} from 'react-router-dom'

export default function Pdf() {
  const [numPages, setNumPages] = useState(null);
  const [url, setUrl] = useState(null)
  const [searchParams] = useSearchParams()
  const id  = searchParams.get('id')

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }

  useEffect(()=>{
    api.request('past-report/' + id, (r)=>{
      setUrl(api.url.fileBase + r.file?.url)
    })
  }, [id])

  if (!url) return null

  return (
    <div style={{maxWidth: '100vw', overflow: 'hidden'}}>
    <Document
        onContextMenu={(e)=>e.preventDefault()}
        onLoadSuccess={onDocumentLoadSuccess}
        file={ url }
      >
      {[...Array(numPages)].map((_, index) => (
        <Page key={index} pageNumber={index + 1} renderTextLayer={false} />
        ))}
    </Document>
    {  !numPages &&
      <div className="react-pdf__Page"
      style={{color: 'white', width: '100vw', height: '100vh', alignItems: 'center',
        position: 'absolute', left: 0, top: 0, zIndex: 99
      }}>Loading PDF..</div>
    }
    </div>
  )
}
