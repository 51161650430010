import axios from 'axios';
import {fileNameAndExt} from './Functions';
import toast  from 'react-hot-toast';
import React from 'react'
import moment from 'moment'
const CancelToken = axios.CancelToken;

export const __isDebug = '_self' in React.createElement('div')

let baseAdminDomain = global.window && window.location.host.toString();
const scheme = __isDebug?'http://':'https://';
const wsScheme = __isDebug?'ws://':'wss://';

let baseDomain;
switch (baseAdminDomain) {
  case "tmt-insights.com":
    baseDomain = "api-beta.tmt-insights.com";
    break;
  case "sandbox.tmt-insights.com":
    baseDomain = "api-sandbox.tmt-insights.com";
    break;
  default:
  baseDomain = __isDebug ? 'localhost:3333' : "api-beta.tmt-insights.com"
}

const baseUrl = scheme+baseDomain
const baseApiUrl = baseUrl;
const staticAssetUrl = scheme+baseDomain+'/'
const baseAdminUrl = scheme+baseAdminDomain

const requester = axios.create({
  baseURL: baseApiUrl
});

export const showCommonError = function (error) {
    if (error.response && error.response.data) {
        var msg;
        switch (error.response.status) {
            case 401:
                msg = 'Authentication error';
            break;
            case 403:
                msg = 'Forbidden';
            break;
            case 404:
                msg = 'Data not found';
            break;
            //case 500:
                //msg = 'เกิดข้อผิดพลาดเซิฟเวอร์ กรุณาติดต่อเจ้าหน้าที่';
            //break;
            default:
                  msg = ''
                if (error.response.data.errors) {
                  msg = error.response.data.errors.map(({message})=>message).join(' | ')
                } else if (error.response.data.message) {
                  msg = error.response.data.message
                }
            break;
        }

        toast.error(msg.replaceAll(' | ', "\n"))
    }
};

const objectToFormData = function(obj, form, namespace) {

  var fd = form || new FormData();
  var formKey;

  for(var property in obj) {

    if(obj.hasOwnProperty(property)) {
      if(namespace) {
        formKey = namespace + '[' + property + ']';
      } else {
        formKey = property;
      }

      if (obj[property] instanceof Date) {
        fd.set(formKey, moment(obj[property]).toISOString());
      } else if (obj[property] === null) {
        fd.set(formKey, '');
      } else if(typeof obj[property] === 'object'  && !(obj[property] instanceof File)) {
        objectToFormData(obj[property], fd, formKey);
      } else {
        // if it's a string or a File object
        if (obj[property] !== undefined)
        fd.set(formKey, obj[property]);

      }

    }
  }

  return fd;

};

const form = (params, files, _formData) => {
    var formData = objectToFormData(params, _formData)

    if (files) {
        for (var i in files) {
            if (Array.isArray(files[i])){
                for (var j in files[i]) {
                    if (files[i][j] instanceof File || files[i][j] instanceof Blob) {
                      formData.append(i + '[]', files[i][j]);
                    }
                    //  else {
                    //   const uri = files[i][j].uri?files[i][j].uri:files[i][j].path;
                    //   const image = {
                    //       uri: uri,
                    //       type: files[i][j].type?files[i][j].type:files[i][j].mime,
                    //       name: files[i][j].name ? files[i][j].name : fileNameAndExt(uri)
                    //   };
                    //
                    //   formData.append(i + '[]', image);
                    // }
                }
            } else {
                if (files[i] instanceof File || files[i] instanceof Blob) {
                  formData.append(i, files[i]);
                }
                // else {
                //   const uri = files[i].uri?files[i].uri:files[i].path;
                //   const image = {
                //       uri: uri,
                //       type: files[i].type?files[i].type:files[i].mime,
                //       name: files[i].name ? files[i].name : fileNameAndExt(uri)
                //   };
                //
                //   formData.append(i, image);
                // }
            }
        }
    }

    formData = modifier(formData, params);

    return formData;
}

const getToken = () => {
    return localStorage.getItem('@PLI:api_token');
}

const getHeader = (token = null) => {
    token = token?token:getToken();

    if (!token) {
        return {};
    }

    return {'Authorization': 'Bearer ' + token};
}

export const getLink = () => {
  return {
    url: scheme+baseDomain,
    baseUrl: baseUrl,
    headers: getHeader()
  }
}

const api =  {
     requestPromise: (url, params = {}, method = 'GET', options) => {

         options = {...{showError: true}, ...options}
         const cancelSource = CancelToken.source();
         const cancelToken = cancelSource.token
         const {showError} = options

         return requester(Object.assign({
             method,
             url,
             headers: getHeader(),
             cancelToken
         }, method == 'GET'?{params: params}:{data:params}))
     },
     request: (url, callback = (r, s)=>{}, params = {}, method = 'GET', options = {}) => {

         options = {...{showError: method!='GET', showSuccess: false}, ...options}

         const cancelSource = CancelToken.source();
         const cancelToken = cancelSource.token
         const {showError, baseURL, forceCallbackError} = options

         requester(Object.assign({
             method,
             url,
             baseURL,
             headers: getHeader(options.bearerToken),
             cancelToken
         }, method == 'GET'?{params: params}:{data:params})).then((response)=>{
             callback(response.data, true);
             if (options.showSuccess) {
               toast.success(response.data.message?response.data.message:(method=='DELETE'?'Data removed !':'Data saved !'))
             }
         }).catch((error) => {
            console.log(error)
             if (forceCallbackError) {
               callback(error, false)
               return
             }

             if (!error.response) {
                 return;
             }

             if (showError) {
                 showCommonError(error);
             }

             callback(error, false)
         });

         return cancelSource
     },
     showCommonError: showCommonError,
     form: form,
     url: {
         baseDomain,
         base: baseUrl,
         baseApi: baseApiUrl,
         ws: wsScheme + baseDomain,
         admin: baseAdminUrl,
         staticAsset: staticAssetUrl,
         fileBase: baseUrl + '/uploads/'
     },
     getToken,
     applyLanguages: (fields, data) => {
       for (var k in data) {
            if (fields.indexOf(k) > -1 && typeof data[k] == 'string') {
              data[k] = {
                th: data[k],
                en: data[k]
              }
            }
        }

        return data
     }
}

function modifier(formData, params) {
    //append something

    return formData;
}

export default api;
