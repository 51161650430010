import logo from './logo.svg';
import './App.css';
import {Container, Row, Col, Form, Button} from 'react-bootstrap'
import DatePicker from 'react-datepicker'
import {useState, useEffect} from 'react'
import toast from 'react-hot-toast';
import Header from './components/Header'
import {tickers} from './App'
import Modal from 'react-bootstrap/Modal';
import Table from 'react-bootstrap/Table';
import moment from 'moment'
import api from './utils/Api'


const DatepickerInput = ({ ...props }) => {
  return <Form.Control {...props} readOnly={!props.enableTyping} />
};


function Home() {
  const [form, setForm] = useState({})
  const [file, setFile] = useState(null)
  const [show, setShow] = useState(false);
  const [sort, setSort] = useState({
    orderBy: 'date',
    order: 'desc'
  })
  const [data, setData] = useState([])
  const [uploading, setUploading] = useState(false)
  const user = JSON.parse(localStorage.getItem('@PLI:user'))

  const canDelete = user.canDelete
  const readOnly = user.readOnly


  const fetch = () => {
    api.request('past-report', (r,s)=>{
      setData(r)
    }, sort)
  }

  useEffect(()=>{
    fetch()
  }, [sort])

  return (
    <div className="App">
      <Header />
      <div style={{maxWidth: 1200}} className="form-container">
      <Container>
        <Row>
          {!readOnly &&
          <Col xs={12} className="d-flex justify-content-end mb-3">
            <Button onClick={()=>setShow(true)} size="sm">Upload new PDF</Button>
          </Col>
          }
          <Col xs={12}>
          <Table responsive striped bordered>
            <thead>
              <tr>
                <th onClick={()=>{
                  setSort({orderBy: 'date', order: sort.order == 'asc'?'desc':'asc'})
                }} style={{minWidth: 160}}>Publication Date {sort.orderBy == 'date'? (sort.order=='asc'?<small> ▲</small>:<small> ▼</small>) : ''}</th>
                <th onClick={()=>{
                  setSort({orderBy: 'ticker', order: sort.order == 'asc'?'desc':'asc'})
                }}>Ticker {sort.orderBy == 'ticker'? (sort.order=='asc'?<small> ▲</small>:<small> ▼</small>) : ''}</th>
                <th>Link</th>
                <th>Description</th>
                <th style={{minWidth: 150}}>Uploaded on</th>
                {canDelete && <th>Action</th>}
              </tr>
            </thead>
            <tbody>
              {
                !data.length && <tr><td colSpan={canDelete?6:5}>No data found.</td></tr>
              }
              {
                data.map((row, index)=>(
                  <tr key={index}>
                    <td>{moment(row.date).format('MM/DD/YYYY')}</td>
                    <td>{row.ticker}</td>
                    <td><a href={readOnly?`/pdf?id=${row.id}`:api.url.fileBase + row.file.url} target="__blank">{row.file.url}</a></td>
                    <td>{row.description}</td>
                    <td>{moment(row.createdAt).format('LLL')}</td>
                    {canDelete && <td><Button onClick={()=>{
                      if (window.confirm('Are you sure to delete this file?')) {
                        api.request('past-report/'+row.id, (r)=>{
                          fetch()
                        }, {}, 'DELETE')
                      }
                    }} size="sm" variant="danger">Delete</Button></td>}
                  </tr>
                ))
              }
            </tbody>
          </Table>
          </Col>
        </Row>
      </Container>
      </div>
      <Modal show={show} onHide={()=>setShow(false)}>
      <Modal.Header closeButton>
        <Modal.Title>Upload file</Modal.Title>
      </Modal.Header>
      <Modal.Body>
      <Form onSubmit={(e)=>{
        e.preventDefault()
      }}>
        <Container>
          <Row>
            <Col xs={12}>
              <Form.Label>Target Publication Date <span className="text-danger">*</span></Form.Label>
              <DatePicker selected={form.date} onChange={(date)=>{
                setForm({...form, date})
              }} customInput={<DatepickerInput />} />
            </Col>
            <Col xs={12} className="mt-3">
              <Form.Label>Ticker <span className="text-danger">*</span></Form.Label>
              <Form.Select onChange={(e)=>{
                setForm({...form, ticker: e.target.value})
              }} aria-label="Default select example">
              <option data-hook="native-option-PLACEHOLDER" data-index="0" value="Select an option">
              Select an option</option>
              {
                tickers.map((ticker, index)=>(
                  <option key={index} value={ticker}>{ticker}</option>
                ))
              }
              </Form.Select>
            </Col>

            <Col xs={12}  className="mt-3">
              <Form.Label>Description</Form.Label>
              <Form.Control onChange={(e)=>setForm({...form, description: e.target.value})} as="textarea" />
            </Col>
            <Col xs={12}  className="mt-3">
              <Form.Label>PDF File<span className="text-danger">*</span></Form.Label>
              <Form.Control onChange={(e)=>setFile(e.target.files[0])} type="file" accept="application/pdf" />
            </Col>

          </Row>
        </Container>
      </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button disabled={uploading} variant="primary" onClick={()=>{
          if (!file) {
            alert('Please select a file')
            return
          }
          setUploading(true)
          api.request('past-report', (r,s)=>{
            setUploading(false)
            if (s) {
              fetch()
              setShow(false)
            }
          }, api.form(form, {file}), 'PATCH')

        }}>
          {uploading?`Uploading..`:`Submit`}
        </Button>
      </Modal.Footer>
    </Modal>
    </div>
  );
}

export default Home;
