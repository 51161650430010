import _ from "lodash";
import moment from "moment";

export const getLimitedNumber = (number, limit) => {
    if (number <= limit) {
        return number;
    }

    return limit + '+';
}

export function dateRange(startDate, endDate, unit = 'days') {
    endDate = moment(endDate)
    var now = moment(startDate), dates = [];

    while (now.isSameOrBefore(endDate, unit)) {
        dates.push(moment(now));
        now.add(1, unit);
    }

    return dates;
};

export function fileNameAndExt(str){
  var file = str.split('/').pop();

  return file.substr(0,file.lastIndexOf('.')) +'.'+file.substr(file.lastIndexOf('.')+1,file.length);
}

export function getQueryParams(search, raw = false) {
  if (!raw) {
    return Object.fromEntries(new URLSearchParams(search))
  }

  let params = {}

  search.substr(1).split('&').reduce((a, b) => {
    let [key, val] = b.split('=');
    a[key] = val;
    return a;
  }, params)

  return params
}

export function numberFormat(num, hideDecimalWhenInteger = false, zeroText = '0') {
    var tester = parseFloat(num);

    if (num === 0 || num === null || num === undefined || num === '') {
        if (!hideDecimalWhenInteger && zeroText==='0') {
          return '0.00'
        }

        return zeroText;
    }

    if (tester > Math.floor(tester) ) {
        num = tester;
        num = num.toFixed(2);
    } else if (hideDecimalWhenInteger) {
        if (num != '' && num != null){
            num = Math.floor(num);
        }
    } else if (tester == Math.floor(tester) && !hideDecimalWhenInteger) {
      num = parseFloat(num).toFixed(2);
    }

    return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

export function currencyFormat(num, currencyText = '฿') {
  return currencyText + numberFormat(num, 2, '0')
};


export function numberInRange(number, min, max) {
    return Math.max(Math.min(number, max), min);
}

export const optionValue = (_id, opts) => {
    if (_id === undefined || _id === null || _id === '') {
        return null
    }

    return _.find(opts, ['value', _id])
}
