import logo from './logo.svg';
import './App.css';
import {Container, Row, Col, Form, Button} from 'react-bootstrap'
import DatePicker from 'react-datepicker'
import {useState, useEffect} from 'react'
import toast from 'react-hot-toast';
import Header from './components/Header'
import {tickers} from './App'
import Modal from 'react-bootstrap/Modal';
import Table from 'react-bootstrap/Table';
import moment from 'moment'
import api from './utils/Api'


const DatepickerInput = ({ ...props }) => {
  return <Form.Control {...props} readOnly={!props.enableTyping} />
};


function getAction(action) {
  switch (action) {
    case 'log_in':
      return {
        action: 'Log In',
        color: 'text-success'
      }
    break;
    case 'log_out':
    return {
      action: 'Log Out',
      color: 'text-danger'
    }
    break;
  }
}

function Home() {
  const [data, setData] = useState([])
  const [uploading, setUploading] = useState(false)
  const user = JSON.parse(localStorage.getItem('@PLI:user'))

  const canDelete = user.canDelete
  const readOnly = user.readOnly


  const fetch = () => {
    api.request('log-in-log', (r,s)=>{
      setData(r)
    })
  }

  useEffect(()=>{
    fetch()
  }, [])

  return (
    <div className="App">
      <Header />
      <div style={{maxWidth: 1200}} className="form-container">
      <Container>
        <Row>
          <Col xs={12}>
          <Table responsive striped bordered>
            <thead>
              <tr>
                <th>User</th>
                <th>Action</th>
                <th style={{minWidth: 150}}>Logged on</th>
              </tr>
            </thead>
            <tbody>
              {
                !data.length && <tr><td colSpan={canDelete?6:5}>No data found.</td></tr>
              }
              {
                data.map((row, index)=>{
                  const {color, action} = getAction(row.action)

                  return <tr key={index}>
                    <td>{row.user.email}</td>
                    <td className={color}>{action}</td>
                    <td>{moment(row.createdAt).format('LLL')}</td>
                  </tr>
                })
              }
            </tbody>
          </Table>
          </Col>
        </Row>
      </Container>
      </div>
    </div>
  );
}

export default Home;
