import logo from './logo.svg';
import './App.css';
import {Container, Row, Col, Form, Button} from 'react-bootstrap'
import DatePicker from 'react-datepicker'
import {useState, useEffect, useRef} from 'react'
import toast from 'react-hot-toast';
import Header from './components/Header'
import {tickers} from './App'
import api from './utils/Api'
import moment from 'moment'
import _ from 'lodash'
import { CSVLink, CSVDownload } from "react-csv";

const DatepickerInput = ({ ...props }) => {
  return <Form.Control {...props} readOnly={!props.enableTyping} />
};


function Schedule() {
  const [data, setData] = useState(null)
  const csvLink = useRef(null)
  const user = JSON.parse(localStorage.getItem('@PLI:user'))

  const canExport = user.canDelete

  useEffect(()=>{
    api.request('schedule', (r)=>{
      setData(r.map((item, index)=>({
        no: index+1,
        ticker: item.ticker,
        description: item.description?.replaceAll(/"/g, '""').replaceAll(/,/g, '\,').replaceAll(/'/g, '\''),
        date: item.date?moment(item.date).format('MM/DD/YYYY'):''
      })))
    })
  }, [])

  if (!data) return null

  return (
    <div className="App">
      <Header />
      <div className="form-container">
      { canExport &&
        <div className="d-flex justify-content-end">
          <Button onClick={()=>{
            setTimeout(()=>csvLink.current.link.click(), 200)
          }}>Export CSV</Button>
        </div>
      }
      <Form onSubmit={(e)=>{
        e.preventDefault()
        const err = []
        const form = new FormData(e.target)
        const user = JSON.parse(localStorage.getItem('@PLI:user'))

        api.request('schedule', (r,s)=>{
          toast.success('Submit successfully !')
        }, api.form({email: user.email}, {}, form), 'PATCH')
      }}>
      <Container>
        {
          tickers.map((ticker, index)=>{
            const i = _.findIndex(data, ['ticker', ticker])

            return <Row key={index}  className="mt-3">
              <Col xs={12}>
                <h4>{ticker}</h4>
              </Col>
              <Col sm={12}>
                <Form.Label>Target Publication Date <span className="text-danger">*</span></Form.Label>
                <DatePicker selected={data[i]?data[i].date:null} onChange={(date)=>{
                  const _data = [...data]
                  _data[i] = {
                    ..._data[i], date
                  }
                  setData(_data)
                }} customInput={<DatepickerInput />} />
                <input type="hidden" value={data[i]?
                  moment(data[i].date).toISOString():''} name={"data["+ index +"][date]"} />
              </Col>
              <Col sm={12} className="mt-3">
                <Form.Label>Description</Form.Label>
                <Form.Control as="textarea" name={"data["+ index +"][description]"}
                 placeholder="Enter text here" defaultValue={data[i]?.description} />
              </Col>
              <Col xs={12}>
                <hr className="mt-5" />
              </Col>
              <input type="hidden" value={ticker} name={"data["+ index +"][ticker]"} />
            </Row>
          })
        }
        <Row>
          <Col xs={12} className="d-flex justify-content-center mt-4 mb-3">
            <Button type="submit">Submit</Button>
          </Col>
        </Row>
      </Container>
      </Form>
      </div>
      <CSVLink
        filename={`schedule_report_${moment().format('MM-DD-YYYY')}.csv`}
        data={data}
        headers={[
          {label: 'No.', key: 'no'},
          {label: 'Ticker', key: 'ticker'},
          {label: 'Date', key: 'date'},
          {label: 'Description', key: 'description'},
        ]}
        ref={csvLink}
      />
    </div>
  );
}

export default Schedule;
