import {useState} from "react"
import toast from 'react-hot-toast';
import api from './utils/Api'
import _ from 'lodash'

export default function ({onLogin}) {


  return (
    <div className="Auth-form-container">
      <form onSubmit={(e)=>{
        e.preventDefault()

        const formData = new FormData(e.target)

        api.request('login', (r,s)=>{
          if (!s) return

          localStorage.setItem('@PLI:api_token', r.token.token);
          localStorage.setItem('@PLI:user', JSON.stringify(r.user));
          onLogin(r.user)
        }, api.form({},null,formData), 'POST')
      }} className="Auth-form">
        <div className="Auth-form-content">
          <h3 className="Auth-form-title">Log In</h3>
          <div className="form-group mt-3">
            <label>Email address</label>
            <input
              type="email"
              className="form-control mt-1"
              placeholder="Enter email"
              name="email"
            />
          </div>
          <div className="form-group mt-3">
            <label>Password</label>
            <input
              type="password"
              className="form-control mt-1"
              placeholder="Enter password"
              name="password"
            />
          </div>
          <div className="d-grid gap-2 mt-4 mb-3">
            <button type="submit" className="btn btn-primary">
              Submit
            </button>
          </div>
          {/*<p className="forgot-password text-right mt-2">
            Forgot <a href="#">password?</a>
          </p>*/}
        </div>
      </form>
    </div>
  )
}
